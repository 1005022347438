<template>
	<v-card
		class="pa-4 mt-4"
		v-if="register && register.signers && ['PENDING', 'APPROVED'].includes(status)"
	>
		<v-row align="center">
			<v-col cols="12" sm="auto">
				<v-icon color="blue" x-large>mdi-shield-check-outline</v-icon>
			</v-col>
			<v-col>
				<p class="my-1 grey--text" v-if="manager">
					El contenido de este documento
					<span class="green--text">ha sido generado</span> por
					<span class="primary--text text-capitalize font-weight-medium">{{
						capitalize(manager.full_name)
					}}</span>
					con firma electrónica el
					{{ manager.date_confirmation }}
				</p>
				<p class="my-1 grey--text" v-if="responsible">
					El contenido de este documento
					<span class="blue--text">ha sido validado</span> por
					<span class="primary--text text-capitalize font-weight-medium">{{
						capitalize(responsible.full_name)
					}}</span>
					con firma electrónica el
					{{ responsible.date_confirmation }}
				</p>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
export default {
	name: 'ResponsibleInspection',
	props: {
		register: Object,
	},
	data: () => ({}),
	computed: {
		status: function () {
			return this.register?.status || null;
		},
		manager: function () {
			return this.register?.signers?.manager || null;
		},
		responsible: function () {
			return this.register?.signers?.responsible || null;
		},
	},
	mounted: function () {},
	methods: {
		capitalize(text = '') {
			return text.toLowerCase().replace(/\b./g, function (a) {
				return a.toUpperCase();
			});
		},
	},
};
</script>
