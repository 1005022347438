import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import FormHeader from '@/components/inspections/formats/format-a/headers/FormHeader.vue';
import FormBody from '@/components/inspections/formats/format-a/bodies/FormBody.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import ResponsibleInpection from '@/components/global/ResponsibleInpection.vue';
import RegistryCreator from '@/components/global/RegistryCreator.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersShow',
	data: () => ({
		title: '',
		breadcrumbs: [],
		steps: {
			current: 1,
			views: [1],
		},
		isLoadingData: false,
		isLoadingSave: false,
		validationErrors: [],
	}),
	computed: {
		...mapState('register', ['register']),

		isMobile: function () {
			return this.$vuetify.breakpoint.width < 960;
		},
		totalQuestions: function () {
			let total = 0;
			this.inspection.bodies.forEach((body) => {
				total += body.items.length;
			});
			return total;
		},
		totalItemsApply: function () {
			let total = 0;
			if (this.inspection && Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (['SI', 'NO'].includes(item.value)) {
							total++;
						}
					});
				});
			}
			return total;
		},
		totalItemsResponse: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (item.value && item.value != '') {
							total++;
						}
					});
				});
			}
			return total;
		},
		optionsAnswer: function () {
			let options = [];
			if (this.inspection && Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((body) => {
					body.options_answer.forEach((option) => {
						let exists = false;
						options.forEach((optionItem) => {
							if (optionItem.value == option.value) {
								exists = true;
							}
						});
						if (!exists) {
							options.push({
								label: option.label,
								value: option.value,
								color: option.color,
								total: 0,
							});
						}
					});
				});

				// SET TOTALS
				this.inspection.bodies.forEach((body) => {
					body.items.forEach((item) => {
						options.forEach((optionItem, indexOI) => {
							if (item.value == optionItem.value) {
								options[indexOI].total++;
							}
						});
					});
				});
			}
			return options;
		},
		totalAnswers: function () {
			let total = 0;
			this.optionsAnswer.forEach((option) => {
				total += option.total;
			});
			return total;
		},
		validateObservations: function () {
			let result = true;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (
							item.value &&
							item.value == 'NO' &&
							(!item.observations || item.observations == '')
						) {
							result = false;
						}
					});
				});
			}
			return result;
		},
		completedQuestionary: function () {
			return (
				this.totalItemsResponse == this.totalQuestions &&
				this.totalItemsResponse > 0 &&
				this.validateHeader() &&
				this.validateObservations
			);
		},
		inspection: {
			get() {
				return this.$store.state.inspection.inspection;
			},
			set(value) {
				this.$store.commit('inspection/SET_INSPECTION', value);
			},
		},
	},
	created() {
		this.getData();
		$EventBus.$emit('showSubNav', false);
	},
	watch: {
		'steps.current': function (newVal) {
			if (!this.steps.views.includes(newVal)) {
				this.steps.views.push(newVal);
			}
		},
	},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', [
			'findById',
			'updateStatus',
			'pdf',
			'create',
			'update',
			'cleanRegister',
		]),

		textTransform,

		getData() {
			this.isLoadingData = true;
			this.findById({
				success: () => {
					if (this.register) {
						this.find({
							success: () => {
								this.setDataInit();
								this.setBreadCrumbs();
								this.isLoadingData = false;
							},
							fail: () => {
								this.isLoadingData = false;
							},
							id: this.$route?.params?.inspectionId,
						});
					}
				},
				fail: this.handleFail,
				params: {
					inspectionId: this.$route.params.inspectionId,
					registerId: this.$route.params.id,
				},
			});
		},
		validateHeader() {
			let result = true;
			if (Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		validateGroup(index) {
			let result = true;
			if (Array.isArray(this.inspection?.bodies[index]?.items)) {
				this.inspection.bodies[index].items.forEach((body) => {
					if (
						!body.value ||
						body.value === undefined ||
						body.value == '' ||
						(body.value &&
							body.value === 'NO' &&
							(!body.observations || body.observations == ''))
					)
						result = false;
				});
			}
			return result;
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		groupData(status = 'PENDING') {
			// INITIAL DATA
			const service_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});
			const date = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'date',
				propFound: 'value',
			});
			const manager_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'manager',
				propFound: 'value',
			});
			const management_id = this.findValueInArray({
				items: this.$store.state.security.costCenters,
				prop: 'id',
				val: service_id,
				propFound: 'management_id',
			});
			let dataSend = {
				register: {
					register_id: parseInt(this.$route.params.id),
					inspection_id: this.inspection.id,
					format: this.inspection.format,
					service_id: parseInt(service_id),
					status,
					date,
					manager_id: parseInt(manager_id),
					management_id,
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				headers: [],
				bodies: [],
			};

			// SET HEADERS
			dataSend.headers = this.inspection.headers.map(function (header) {
				return { header_id: header.id, value: (header?.value || '').toString() };
			});

			// SET BODIES
			this.inspection.bodies.forEach((body) => {
				body.items.forEach((item) => {
					dataSend.bodies.push({
						compliance: item.value,
						observations: item.observations,
						body_id: item.id,
					});
				});
			});
			return dataSend;
		},
		backPage() {
			if (this.$route.name == 'InspectionsRegisterRevision') {
				this.$router.push({
					name: 'InspectionsRegisterListRevision',
				});
			} else {
				this.$router.push({
					name: 'InspectionsRegisterList',
					params: { id: this.$route.params.inspectionId },
				});
			}
		},
		send(status = 'PENDING') {
			this.isLoadingSave = true;
			this.update({
				success: () => {
					this.isLoadingSave = false;
					if (this.$route.name == 'InspectionsRegisterRevision') {
						this.$router.push({
							name: 'InspectionsRegisterListRevision',
						});
					} else {
						this.$router.push({
							name: 'InspectionsRegisterList',
							params: { id: this.$route.params.inspectionId },
						});
					}
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					this.isLoadingSave = false;
					const { response } = error;
					if (response && response?.status == 400 && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					}
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información.'
					);
				},
				data: this.groupData(status),
			});
		},
		setDataInit() {
			// this.inspection.headers.forEach((header) => {
			// 	// SET HEADERS
			// 	// this.register.registerHeaders.forEach((rH) => {
			// 	// 	if (rH.header_id == header.id) {
			// 	// 		header.value = rH.value;
			// 	// 		header.disabled = this.isDisabled();
			// 	// 	}
			// 	// });
			// });
			const headers = this.register?.registerHeaders || null;
			if (Array.isArray(headers)) {
				headers.forEach((header) => {
					this.$store.dispatch('inspection/updateInputHeader', {
						id: header.header_id,
						value: header.value,
						disabled: this.isDisabled(),
					});
				});
			}

			this.inspection.bodies.forEach((group) => {
				group.items.forEach((body) => {
					// SET BODIES
					this.register.bodyRegisters.forEach((bR) => {
						if (bR.body_id == body.id) {
							body.value = bR.compliance;
							body.observations = bR.observations;
						}
					});
				});
			});
		},
		approve() {
			Swal.fire({
				title: '¿Está seguro que desea aprobar esta inspección?',
				text: 'Confirme acción',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No, Cancelar',
				confirmButtonText: '¡Si, Aprobar!',
			}).then((result) => {
				if (result.value) {
					this.isLoadingSave = true;
					this.updateStatus({
						success: () => {
							this.isLoadingSave = false;
							if (this.$route.name == 'InspectionsRegisterRevision') {
								this.$router.push({
									name: 'InspectionsRegisterListRevision',
								});
							} else {
								this.$router.push({
									name: 'InspectionsRegisterList',
									params: { id: this.$route.params.inspectionId },
								});
							}
							$EventBus.$emit(
								'showSnack',
								'success',
								'Se ha registrado satisfactoriamente.'
							);
						},
						fail: (error) => {
							this.isLoadingSave = false;
							const { response } = error;
							if (response && response?.data?.message) {
								this.validationErrors = Array.isArray(
									response?.data?.message
								)
									? response?.data?.message
									: [];
							}
							$EventBus.$emit(
								'showSnack',
								'error',
								'Algo salio mal, no se guardo la información.'
							);
						},
						data: {
							format: this.inspection.format,
							id: this.register.id,
						},
					});
				}
			});
		},
		getLinkPdf(id) {
			let link = '';
			this.pdf({
				success: (response) => {
					link = response;
				},
				fail: this.handleFail,
				id: id,
			});
			return link;
		},
		setBreadCrumbs() {
			if (this.$route.name === 'InspectionsRegisterListRevisionShow') {
				this.breadcrumbs = [
					{
						text: 'Revisiones',
						link: true,
						exact: true,
						disabled: false,
						to: {
							name: 'InspectionsRegisterListRevision',
						},
					},
					{
						text: 'Registro',
						disabled: true,
						href: '/',
					},
				];
			} else {
				this.breadcrumbs = [
					{
						text: 'Inspecciones',
						link: true,
						exact: true,
						disabled: false,
						to: {
							name: 'InspectionsList',
						},
					},
					{
						text: this.inspection
							? this.textTransform(this.inspection.name)
							: '',
						link: true,
						exact: true,
						disabled: false,
						to: {
							name: 'InspectionsRegisterList',
							params: { id: this.inspection.format },
						},
					},
					{
						text: 'Registro',
						disabled: true,
						href: '/',
					},
				];
			}
		},
		getLabel(value) {
			let newLabel = '';
			if (value.toLowerCase() == 'si') {
				newLabel = 'Cumplimiento';
			} else if (value.toLowerCase() == 'no') {
				newLabel = 'Incumplimiento';
			} else if (value.toLowerCase() == 'na') {
				newLabel = 'No Aplica';
			}
			return newLabel;
		},
		setValueRadio(value, idGroup, idItem) {
			let data = this.inspection.bodies.map((group, indexGroup) => {
				if (indexGroup == idGroup) {
					const items = group.items.map((item, indexItem) => {
						if (indexItem == idItem) {
							return { ...item, value };
						} else {
							return item;
						}
					});
					return { ...group, items };
				} else {
					return group;
				}
			});
			this.$set(this.inspection, 'bodies', data);
		},
		isDisabled() {
			return true;
		},
		printPdf() {
			window.open(
				`/dashboard/inspections/${this.$route.params.inspectionId}/registers/${this.register.id}/pdf`,
				'Pdf',
				'resizable,scrollbars,status'
			);
		},
	},
	destroyed() {
		this.cleanRegister();
		this.cleanInspection();
	},
	components: {
		FormHeader,
		FormBody,
		ValidationAlert,
		ResponsibleInpection,
		RegistryCreator,
	},
};
